export default defineRequestMiddleware(async () => {
  if (useRuntimeConfig().public.nh) {
    updateSiteConfig({
      url: "https://www.netzwerk-hoergesundheit.de",
      name: "Netzwerk Hörgesundheit",
      description:
        "Wir sind ein SINNergetisches Netzwerk mit ganzheitlich wissenschaftsbasierten Lösungen aus einer Hand. Durch unsere auf den Kunden fokussierten innovativen Produkte und Dienstleistungen ermöglichen wir ein hörgesundes Leben voller Vitalität und Hörgenuss für alle.",
      linkedin: "https://www.linkedin.com/company/netzwerk-hoergesundheit",
      facebook: "https://www.facebook.com/audoora.de",
      instagram: "https://www.instagram.com/netzwerk_hoergesundheit",
    })
  }
})
